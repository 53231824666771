<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent :id="model?.system?.id" :component="!model?.system?.workflow">
      <SmartLinkStandaloneElement :codename="type.elements.hero_slides.codename">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div
                id="homeHero"
                ref="carousel"
                class="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="5000"
              >
                <SmartLinkItemComponent
                  :id="model?.system?.id"
                  class="carousel-inner"
                  :component="!model?.system?.workflow"
                >
                  <HeroSlide
                    v-for="(slide, index) in data.slides"
                    :key="slide?.system?.id"
                    :model="slide"
                    :index="index"
                    :is-active="carouselActiveSlide == index"
                  />
                </SmartLinkItemComponent>
                <div class="carousel-indicators">
                  <button
                    v-for="(_slide, index) in data.slides"
                    :key="`carousel-indicators-${model?.system?.id}-${index}`"
                    type="button"
                    data-bs-target="#homeHero"
                    :data-bs-slide-to="index"
                    :class="{ active: carouselActiveSlide == index }"
                    :aria-current="carouselActiveSlide == index"
                    :aria-label="`Slide ${index}`"
                    :disabled="carouselIsAnimating"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SmartLinkStandaloneElement>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import { type HomeHeroSlideModel, type HomeHeroModel } from '~/models'
import { contentTypes } from '~/models'
import shuffle from 'lodash/shuffle'

const carousel = ref<HTMLElement | null>(null)
const carouselIsAnimating = ref(false)
const carouselActiveSlide = ref(0)

const updateActiveSlide = () => {
  if (carousel.value) {
    // update carousel 'active index' this way because
    //  it needs to be set after the transition animation is completed
    const activeItem = carousel.value.querySelector('.carousel-item.active')
    const items = Array.from(carousel.value.querySelectorAll('.carousel-item'))
    carouselActiveSlide.value = items.indexOf(activeItem as Element)
    carouselIsAnimating.value = false
  }
}

const type = contentTypes.home_hero
const props = defineProps<{ model: HomeHeroModel }>()

const pageStore = usePageStore()
const data = computed(() => {
  return {
    slides: shuffle(pageStore.getListAs<HomeHeroSlideModel>(props.model?.elements.heroSlides.value))
  }
})

onMounted(() => {
  if (carousel.value) {
    useEventListener(carousel.value, 'slide.bs.carousel', () => (carouselIsAnimating.value = true))
    useEventListener(carousel.value, 'slid.bs.carousel', updateActiveSlide)
    updateActiveSlide()
  }
})
</script>
